import { Modal } from "antd";
import React from "react";

import "./BlackFridayOffer.css";

const BlackFridayOfferModal = ({ isModalVisible, handleCancel }) => {
  return (
    <Modal
      open={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      centered="true"
      className="blackFridayOfferModalContainer"
      bodyStyle={{ backgroundColor: "#1ebcc5" }}
    >
      <a
        className="
      promo-modal-redirection-link
      "
        href="https://api.whatsapp.com/send/?phone=5519991992975&text&type=phone_number&app_absent=0"
        target="_blank"
        rel="noreferrer"
      >
        <div className="promo-modal-content">
          <img
            src="/assets/svgs/1.svg"
            alt="Black Friday Alesta"
            className="promo-house-icon"
          />
          <div className="promo-highlight-container">
            <img src="/assets/svgs/2.svg" alt="Black Friday Alesta" />
            <h2 className="promo-highlight">SUPER</h2>
            <img src="/assets/svgs/2.svg" alt="Black Friday Alesta" />
          </div>
          <h1 className="promo-highlight">BLACK</h1>
          <img
            src="/assets/svgs/3.svg"
            alt="Black Friday Alesta"
            className="promo-logo"
          />

          <p className="promo-subtitle">Até 40% de desconto</p>
          <p className="promo-text font-weight-bold">
            para você pagar suas parcelas negativadas.
          </p>
          <p
            className="promo-text"
            style={{
              fontSize: "12px",
              marginBottom: "12px",
            }}
          >
            <strong>**Sujeito à análise. Promoção válida até 06/12.</strong>
          </p>

          <h2 className="promo-title full highlight-accent">
            SUPER BLACK ALESTA ESTENDIDA!
          </h2>

          <div
            className="promo-title split"
            style={{
              
            }}
          >
            <h2 className="promo-title highlight-accent">SUPER BLACK ALESTA</h2>
            <h2 className="promo-title highlight-accent">ESTENDIDA!</h2>
          </div>
          <p
            className="promo-text"
            style={{
              fontSize: "16px",
              padding: "0 20px",
            }}
          >
            Promoção prorrogada! Aproveite para zerar sua dívida com desconto
            imperdível!
          </p>
        </div>
      </a>
    </Modal>
  );
};

export default BlackFridayOfferModal;
